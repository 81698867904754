<template>
  <div class="login">
    <div class="container">
      <div class="box">
        <el-image
          class="logo"
          :src="logoSrc"
        ></el-image>
        <p class="desc">叮当充电</p>
      </div>
      <el-form
        :model="loginFormData"
        :rules="loginRules"
        ref="loginForm"
        class="login-form"
      >
        <el-form-item prop="userName">
          <el-input
            class="input"
            clearable
            placeholder="用户名"
            v-model="loginFormData.userName"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-user icon-input"
            ></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            class="input"
            show-password
            placeholder="密码"
            v-model="loginFormData.password"
            @keyup.enter.native="handleLogin('loginForm')"
          >
            <i
              slot="prefix"
              class="el-input__icon el-icon-lock icon-input"
            ></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="login-btn"
            type="primary"
            @click="handleLogin('loginForm')"
          >
            登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import logoSrc from '@/assets/logo.png'
import { mapActions, mapMutations } from 'vuex'
import { hideLoading } from '../../utils/loading'

export default {
  name: 'login',
  data () {
    return {
      logoSrc,
      loginFormData: {
        userName: '',
        password: ''
      },
      loginRules: {
        userName: [
          { required: true, message: '请输入用户名！', trigger: 'blur' },
          { min: 3, max: 30, message: '用户名长度在3到30个字符!', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码！', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    hideLoading()
  },
  methods: {
    ...mapActions([
      'login'
    ]),
    ...mapMutations([
      'setTabList'
    ]),
    handleLogin (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = {
            username: this.loginFormData.userName,
            password: this.loginFormData.password
          }
          this.login(params).then((res) => {
            // if(res === 'new'){
            this.setTabList([])
            // }
            this.$router.push({
              name: 'home'
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  background-image: url("../../assets/body.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
  background-color: #f7f7f7;

  .container {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .box {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .logo {
        width: 48px;
        margin-right: 10px;
      }

      .desc {
        color: #2f2928;
        font-size: 30px;
      }
    }

    .login-form {
      width: 100%;
    }

    .input {
      background: #ffffff;
    }

    .icon-input {
      font-size: 20px;
    }

    .login-btn {
      width: 100%;
      margin-top: 10px;
    }
  }
}
</style>
